<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText.vue";
import AkInputTextArea from "@components/input/AkInputTextArea.vue";
import AkCheckbox from "@components/input/AkCheckbox.vue";
import AkDropdownMultiple from "@components/input/AkDropdownMultiple.vue";
import AkDateTime from "@components/input/AkDateTime.vue";
import AkInputMask from "@components/input/AkInputMask.vue";
import AkDialog from "@components/general/AkDialog.vue";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";
import precisionTypeInterventionMixin from "@mixins/precisionTypeInterventionMixin";

/* SERVICES */
import interventionService from "@services/interventionService";
import ceiService from "@services/ceiService";
import axeInterventionService from "@services/axeInterventionService";
import userSettingsService from "@services/userSettingsService";

/* CONSTANTES */
import sensConst from "@mixins/const/sensConst";
import origineAppelConst from "@mixins/const/origineAppelConst";
import voieConst from "@mixins/const/voieConst";
import typeInterventionConst from "@mixins/const/typeInterventionConst";
import AkYesNoButton from "@components/input/AkYesNoButton.vue";
import AkCalendar from "@components/input/AkCalendar.vue";
import affectationService from "@services/affectationService";


export default {
  components: {
    AkCalendar,
    AkYesNoButton,
    AkDialog,
    AkInputMask,
    AkDateTime, AkDropdownMultiple, AkCheckbox, AkDropdown, AkInputTextArea, AkInputText, AkFormList
  },
  mixins: [randomRef, utilsMixin, dateFormatter, roleMixin, sensConst, origineAppelConst, voieConst,
    typeInterventionConst, precisionTypeInterventionMixin],
  metaInfo() {
    return {
      title: "Fiches des interventions",
    }
  },
  data() {
    return {
      loading: true,
      intervLoading: true,
      submitted: false,
      interventions: [],
      axeInterventionList: [],
      axeInterventionAutorouteList: [],
      newIntervention: [
        {
          ceiId: '',
          callDate: '',
          callTime: '',
          callOrigin: '',
          axeInterventionId: '',
          sens: '',
          voie: '',
          heureArriveeSite: '',
          heureDepartSite: '',
          localisation: '',
          typeIntervention: '',
          precisionNiveau1: '',
          sensListTmp: [],
        }
      ],
      currentUser: roleMixin.currentUser,
      userParams: roleMixin.userParams,
      userSettings: {},
      userCeis: [],
      userAgers: [],
      precision1: false,
      creationErrors: {},
      editionErrors: {},
      userIntervention: false,
      buttonChangeInterventionLabel: "",
      current: null,
      totalRecords: 100,
      paginateOptions: null,
      prValidatorRegexpFormat: "^[0-9]*\\.{0,1}[0-9]*$",
      isExportDownloading: false,
      isFullExportDownloading: false,
      isCustomExportDownloading: false,
      displayCreateLightIntervention: false,
      searchContent: "",
      selectedRows: new Set(),
      selectAll: false,
      reloadListTimeout: null,
      presenceRi: false,
      ddp: false,
      callOrigin: null,
      axeInterventionId: null,
      sens: null,
      typeIntervention: null,
      author: "",
      filterAgerId: null,
      filterCeiId: null,
      yesNoEmpty: [
        {label: this.$t('yes'), value: true},
        {label: this.$t('no'), value: false},
        {label: this.$t('undefined_short'), value: null},
      ],
      startDate: null,
      endDate: null,
      ceiList: [],
      noInterCei: "",
      tableSettings: [],
      tmpTableSettings: [],
      origineAppel: null,
      lieuIntervention: null,
      intervention: null,
      environnement: null,
      origineAppelDropdown: false,
      lieuInterventionDropdown: false,
      interventionDropdown: false,
      environnementDropdown: false,
    }
  }
  ,
  mounted() {
    if (this.isUserGroupRiCEI()) {
      this.userIntervention = true;
    }
    this.paginateOptions = this.userParams.intervention.paginateOptions;
    this.reloadInterventions();

    if (this.userIntervention) {
      this.buttonChangeInterventionLabel = this.$t("intervention.display_all_intervention");
    } else {
      this.buttonChangeInterventionLabel = this.$t("intervention.display_user_intervention");
    }

    let p4 = axeInterventionService.findAll();
    p4.then(data => {
      this.axeInterventionList = data;
      this.axeInterventionAutorouteList = data.filter(axe => axe.autoroute);
    });

    let p5 = userSettingsService.getTableInterventionSettings(this.currentUser.userId);
    p5.then(data => {
      this.tableSettings = data;
    })

    let p6 = affectationService.findAllByUserId(this.currentUser.userId).then(data => {
      this.ceiList = data.ceiList;
      this.userCeis = data.ceiList;
      this.userAgers = data.agerList;
      this.resetNewIntervention();
    });
    Promise.all([p4, p5, p6]).then(() => {
      this.loading = false;
    });
  }
  ,
  methods: {
    setupEditionErrors() {
      this.interventions.forEach(intervention => {
        this.editionErrors[intervention.id] = {};
      });
    },
    validateIntervention(intervention) {
      let errors = {}

      if (intervention.axeInterventionId === '' || intervention.axeInterventionId === null) {
        errors.axeInterventionId = 'L\'axe d\'intervention est obligatoire'
      }
      if (intervention.callDate === '' || intervention.callDate === null) {
        errors.callDate = 'La date de l\'appel est obligatoire'
      }
      if (intervention.callTime === '' || intervention.callTime === null) {
        errors.callTime = 'L\'heure de l\'appel est obligatoire'
      }
      if (intervention.callOrigin === '' || intervention.callOrigin === null) {
        errors.callOrigin = 'L\'origine de l\'appel est obligatoire'
      }
      if (intervention.localisation === '' || intervention.localisation === null) {
        errors.localisation = 'La localisation est obligatoire'
      }
      if (intervention.ceiId === '' || intervention.ceiId === null) {
        errors.ceiId = 'Le CEI est obligatoire'
      }
      if (this.isAxeInterventionBretelle(intervention.axeInterventionId)) {
        if (intervention.noBretelle === '' || intervention.noBretelle === null || intervention.noBretelle === undefined) {
          errors.noBretelle = 'Le numéro de bretelle est obligatoire'
        }
        if (intervention.autorouteId === '' || intervention.autorouteId === null || intervention.noBretelle === undefined) {
          errors.autorouteId = 'Le numéro d\'autoroute est obligatoire'
        }
      }

      return errors
    },
    resetNewIntervention() {
      this.newIntervention = [{
        "userId": this.currentUser.userId,
        "callDate": new Date(),
        "callTime": '',
        "callOrigin": '',
        "axeInterventionId": '',
        "sens": '',
        "voie": '',
        "heureDebut": '',
        "heureFin": '',
        "ddp": false,
        "presenceChefEquipe": false,
        "presenceRi": false,
        "observation": "",
        "ceiId": (this.userCeis.length === 1) ? this.userCeis[0].id : '',
        "ceiLabel": (this.userCeis.length === 1) ? this.userCeis[0].label : '',
        "localisation": "",
        "typeIntervention": '',
        "precisionNiveau1": '',
        commune: "",
        heureArriveeSite: "",
        heureDepartSite: "",
        moyensHumains: "",
        moyensMateriels: "",
        etatChaussee: "",
        conditionMeteo: "",
        precisionNiveau2: "",
        intervenants: "",
        circonstances: "",
        actionsRealisees: "",
        presenceTiersResponsable: "",
        vehiculesImpliques: "",
        sensListTmp: [],
      }];

      this.newIntervention[0].callTime = this.formatHour(new Date());
    },
    checkInterventionTimeValidity(intervention) {
      intervention.heureArriveeSite = this.checkTimeValidity(intervention.heureArriveeSite);
      intervention.heureDepartSite = this.checkTimeValidity(intervention.heureDepartSite);
      intervention.callTime = this.checkTimeValidity(intervention.callTime);
    },
    checkTimeValidity(time) {
      let timeValues = time.split(":");
      let heures = parseInt(timeValues[0]);
      let minutes = parseInt(timeValues[1]);

      if (heures < 0 || heures > 23) {
        time = "00:" + minutes;
      } else if (minutes < 0 || minutes > 59) {
        time = heures + ":00";
      }
      time = time.replace("NaN", "00");

      return time;
    },
    updateIntervention(intervention) {
      this.getRef().resetMessages();
      intervention.sens = intervention.sensListTmp.join(",");

      let errors = this.validateIntervention(intervention);
      if (Object.keys(errors).length > 0) {
        this.editionErrors[intervention.id] = errors
        this.getRef().error(this.$t("required_fields_empty"));
        return
      } else {
        this.editionErrors[intervention.id] = {}
      }

      errors = interventionService.checkFieldsSize(intervention);
      if (Object.keys(errors).length > 0) {
        this.editionErrors[intervention.id] = errors
        this.getRef().error(this.$t("fields_size_error"));
        return
      } else {
        this.editionErrors[intervention.id] = {}
      }

      this.checkInterventionTimeValidity(intervention);

      interventionService.updateIntervention({intervention: intervention}).then(() => {

        this.submitted = false;
        this.getRef().success(this.$t("intervention.updated"));
      }).catch(error => {
        this.getRef().error(this.$t("unknown_error"));
        console.log(error);
      });
    },
    isEmpty(str) {
      return (!str || 0 === str.length);
    },
    createIntervention(intervention) {
      this.getRef().resetMessages();
      intervention.sens = intervention.sensListTmp.join(",");

      let errors = this.validateIntervention(intervention);
      if (Object.keys(errors).length > 0) {
        this.creationErrors = errors
        this.getRef().error(this.$t("required_fields_empty"));
        return
      } else {
        this.creationErrors = {}
      }

      errors = interventionService.checkFieldsSize(intervention);
      if (Object.keys(errors).length > 0) {
        this.creationErrors = errors
        this.getRef().error(this.$t("fields_size_error"));
        return
      } else {
        this.creationErrors = {}
      }

      let interventionDataDTO = {
        "intervention": intervention,
        "vehiculeImpliqueList": null,
        "ddpList": null
      }

      interventionService.createIntervention(interventionDataDTO).then(() => {
        this.resetNewIntervention();

        this.getRef().success(this.$t("intervention.created"));
        this.reloadInterventions();
      }).catch(error => {
        console.log(error);
      });
    },
    openObservationDialog(data) {
      console.log(data);
      this.current = data;
      this.$refs.dialogObservation.show();
    },
    updateTypeIntervention(intervention) {
      this.precision1 = this.hasPrecision1(intervention.typeIntervention);

      if (!this.precision1) {
        intervention.precisionNiveau1 = null;
      } else {
        if (!this.isPrecision1RelatedToTypeIntervention(intervention.typeIntervention, intervention.precisionNiveau1)) {
          intervention.precisionNiveau1 = null;
        }
      }
    },
    changeAffichageIntervention() {
      if (this.userIntervention) {
        this.userIntervention = false;
        this.buttonChangeInterventionLabel = this.$t("intervention.display_user_intervention");
      } else {
        this.userIntervention = true;
        this.buttonChangeInterventionLabel = this.$t("intervention.display_all_intervention");
      }
      this.reloadInterventions();
    },
    reloadInterventions() {
      if (this.reloadListTimeout) {
        clearTimeout(this.reloadListTimeout);
      }

      this.reloadListTimeout = setTimeout(() => {
        this.intervLoading = true;
        this.getFilterValues();
        let p;
        let params = {
          page: this.paginateOptions.page + 1
          , limit: this.paginateOptions.rows
          , sort: this.paginateOptions.sort
          , searchField: this.searchContent
          , author: this.author
          , callOrigin: this.callOrigin
          , axeInterventionId: this.axeInterventionId
          , sens: this.sens
          , typeIntervention: this.typeIntervention
          , ddp: this.ddp
          , presenceRi: this.presenceRi
          , agerId: this.filterAgerId
          , ceiId: this.filterCeiId
          , startDate: this.startDate
          , endDate: this.endDate
          , noInterCei: this.noInterCei
        };
        if (!this.userIntervention) {
          /////////// Affichage des interventions de concernant l'utilisateur connecté (retiré car tous les groupes voient tout: branche V1.4)
          // if (this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI()) {
          //   p = interventionService.findAllInterventionByCeiId(this.currentUser.ceiId, params);
          // } else if (this.isUserGroupDirectionUER()) {
          //   p = interventionService.findAllInterventionByUerId(this.currentUser.uerId, params);
          // } else if (this.isUserGroupDirectionAGER() || this.isUserGroupCadreN1AGER() || this.isUserGroupUgfAGER()) {
          //   p = interventionService.findAllInterventionByAgerId(this.currentUser.agerId, params);
          // } else {
          //   p = interventionService.findAllIntervention(params);
          // }
          /////////// Affichage des interventions de concernant l'utilisateur connecté (retiré car tous les groupes voient tout: branche V1.4)
          p = interventionService.findAllIntervention(params);
        } else {
          p = interventionService.findAllInterventionByUserId(this.currentUser.userId, params);
        }

        p.then(res => {
          if (null != res.headers['x-pagination-total']) {
            this.totalRecords = res.headers['x-pagination-total'];
          }

          res.data.forEach(intervention => {

            if (intervention.sens !== '') {
              intervention.sensListTmp = intervention.sens.split(",")
            } else {
              intervention.sensListTmp = []
            }

            intervention.callDate = new Date(intervention.callDate);

            if (intervention.callTime == null) {
              intervention.callTime = "";
            }

            intervention.agentFullName = intervention.firstName + " " + intervention.lastName;
          });
          this.interventions = res.data;
          this.selectedRows = new Set();
          this.setupEditionErrors();
          this.intervLoading = false;
        });
      }, 750);
    },
    download() {
      this.isExportDownloading = true;
      let params = {
        author: this.author
        , callOrigin: this.callOrigin
        , axeInterventionId: this.axeInterventionId
        , sens: this.sens
        , typeIntervention: this.typeIntervention
        , ddp: this.ddp
        , presenceRi: this.presenceRi
        , agerId: this.filterAgerId
        , ceiId: this.filterCeiId
        , startDate: this.startDate
        , endDate: this.endDate
      };
      let p = interventionService.downloadInterventionListExcel(params);
      let promises = [p];
      p.catch(() => {
        this.isExportDownloading = false;
        // this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isExportDownloading = false;
      });
    },
    downloadFull() {
      this.isFullExportDownloading = true;
      let params = {
        author: this.author
        , callOrigin: this.callOrigin
        , axeInterventionId: this.axeInterventionId
        , sens: this.sens
        , typeIntervention: this.typeIntervention
        , ddp: this.ddp
        , presenceRi: this.presenceRi
        , agerId: this.filterAgerId
        , ceiId: this.filterCeiId
        , startDate: this.startDate
        , endDate: this.endDate
      };
      let p = interventionService.downloadInterventionListExcelComplet(params);
      let promises = [p];
      p.catch(() => {
        this.isFullExportDownloading = false;
        // this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isFullExportDownloading = false;
      });
    },
    downloadCustom() {
      this.isCustomExportDownloading = true;
      let params = {
        author: this.author
        , callOrigin: this.callOrigin
        , axeInterventionId: this.axeInterventionId
        , sens: this.sens
        , typeIntervention: this.typeIntervention
        , ddp: this.ddp
        , presenceRi: this.presenceRi
        , agerId: this.filterAgerId
        , ceiId: this.filterCeiId
        , startDate: this.startDate
        , endDate: this.endDate
      };
      let p = interventionService.downloadInterventionListExcelCustom(params);
      let promises = [p];
      p.catch(() => {
        this.isCustomExportDownloading = false;
        // this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isCustomExportDownloading = false;
      });
    },
    paginate(event) {
      this.paginateOptions.page = null != event.page ? event.page : 0;
      this.paginateOptions.rows = null != event.rows ? event.rows : 10;
      if (null != event.sortField) {
        let sortPart = []
        sortPart.push(event.sortField);
        sortPart.push(event.sortOrder === 1 ? "ASC" : "DESC")

        if (event.sortField === "call_date") {

          this.paginateOptions.sort = sortPart.join(":");

          sortPart = [];
          sortPart.push("call_time");
          sortPart.push(event.sortOrder === 1 ? "ASC" : "DESC")
          this.paginateOptions.sort = this.paginateOptions.sort + "," + sortPart.join(":");
        } else {
          this.paginateOptions.sort = sortPart.join(":");
        }
      } else {
        this.paginateOptions.sort = null;
      }

      this.userParams.intervention.paginateOptions = this.paginateOptions;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    displayLightInterventionBlock() {
      this.displayCreateLightIntervention = true;
    },
    deleteIntervention() {
      interventionService.deleteIntervention(this.current.id).then(() => {
        this.reloadInterventions();
        this.getRef().success(this.$t("intervention.deleted"));
      }).catch(() => {
        this.getRef().error(this.$t("intervention.deleted_failed"));
      })
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
    onSelectAllChange() {
      this.selectedRows = new Set();
      if (this.selectAll) {
        this.interventions.forEach(intervention => {
          intervention.selected = true;
          this.selectedRows.add(intervention);
        });
      } else {
        this.interventions.forEach(intervention => {
          intervention.selected = false;
        });
      }
    },
    onSelectedChange(data) {
      if (!data.selected) {
        if (this.selectAll) {
          this.selectAll = false;
        }
        this.selectedRows.delete(data);
      } else {
        this.selectedRows.add(data);
      }
    },
    deleteSelectedRows() {
      if (this.selectedRows.size === 0) {
        this.getRef().error(this.$t("intervention.no_intervention_selected"));
        return;
      }
      if (this.selectedRows.length > 50) {
        this.getRef().error(this.$t("intervention.too_many_interventions_selected"));
        return;
      }
      interventionService.deleteList(Array.from(this.selectedRows)).then(() => {
        this.reloadInterventions();
        if (this.selectedRows.size > 1) {
          this.getRef().success(this.$t("intervention.interventions_deleted"));
        } else {
          this.getRef().success(this.$t("intervention.deleted"));
        }
        if (this.selectAll) {
          this.selectAll = false;
          this.selectedRows = new Set();
        }
      }).catch(() => {
        if (this.selectedRows.size > 1)
          this.getRef().error(this.$t("intervention.interventions_not_deleted"));
        else
          this.getRef().error(this.$t("intervention.deleted_failed"));
      })
    },
    openSettingsDialog() {
      userSettingsService.getInterventionSettings(this.currentUser.userId).then(data => {
        this.userSettings = data;

        // Sort settings by ordre
        this.userSettings.settingsValue = this.sortDict(this.userSettings.settingsValue, "ordre");

        if (this.userSettings.settingsValue["callTime"].value && this.userSettings.settingsValue["callDate"].value && this.userSettings.settingsValue["callOrigin"].value && this.userSettings.settingsValue["observation"].value) {
          this.origineAppel = true;
        } else {
          this.origineAppel = false;
        }
        
        console.log(this.userSettings.settingsValue);

        if (this.userSettings.settingsValue["ager"].value && this.userSettings.settingsValue["cei"].value && this.userSettings.settingsValue["agents"].value && this.userSettings.settingsValue["axe"].value && this.userSettings.settingsValue["sens"].value && this.userSettings.settingsValue["voie"].value && this.userSettings.settingsValue["commune"].value && this.userSettings.settingsValue["heureArriveeSite"].value && this.userSettings.settingsValue["heureDepartSite"].value && this.userSettings.settingsValue["localisation"].value && this.userSettings.settingsValue["noBretelle"].value && this.userSettings.settingsValue["noAutoroute"].value) {
          this.lieuIntervention = true;
        } else {
          this.lieuIntervention = false;
        }

        if (this.userSettings.settingsValue["heureDebut"].value && this.userSettings.settingsValue["heureFin"].value && this.userSettings.settingsValue["circonstances"].value) {
          this.intervention = true;
        } else {
          this.intervention = false;
        }

        if (this.userSettings.settingsValue["typeIntervention"].value && this.userSettings.settingsValue["precisionNiveau1"].value && this.userSettings.settingsValue["precisionNiveau2"].value && this.userSettings.settingsValue["etatChaussee"].value && this.userSettings.settingsValue["conditionsMeteo"].value && this.userSettings.settingsValue["intervenants"].value && this.userSettings.settingsValue["actionsRealisees"].value && this.userSettings.settingsValue["presenceTiersResponsable"].value) {
          this.environnement = true;
        } else {
          this.environnement = false;
        }

        this.$refs.settingsDialog.show();
      });
    },
    openTableSettingsDialog() {
      userSettingsService.getTableInterventionSettings(this.currentUser.userId).then(data => {
        this.tableSettings = data;

        // Sort settings by ordre
        this.tableSettings.settingsValue = this.sortDict(this.tableSettings.settingsValue, "ordre");

        this.tmpTableSettings = JSON.parse(JSON.stringify(this.tableSettings));
        if (this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI()) {
          this.tmpTableSettings.settingsValue = this.tmpTableSettings.settingsValue.filter(setting => setting !== "ager" && setting !== "cei");
        }

        this.$refs.tableSettingsDialog.show();
      });
    },
    sortDict(dict, field) {
      let keyValues = []

      for (let key in dict) {
        keyValues.push([key, dict[key]])
      }
      keyValues = keyValues.sort((a, b) => a[1][field] - b[1][field])
      let newDict = {}

      for (let key in keyValues) {
        newDict[keyValues[key][0]] = keyValues[key][1]
      }
      return newDict;
    },
    updateTableSettings() {
      this.$refs.tableSettingsDialog.hide();
      userSettingsService.updateSettings(this.tmpTableSettings).then(() => {
        this.getRef().success(this.$t("settings_saved"));
        this.tableSettings = this.tmpTableSettings;
      }).catch(() => {
        this.getRef().error(this.$t("settings_not_saved"));
      })
    },
    updateSettings() {
      this.$refs.settingsDialog.hide();
      userSettingsService.updateSettings(this.userSettings).then(() => {
        this.getRef().success(this.$t("settings_saved"));
      }).catch(() => {
        this.getRef().error(this.$t("settings_not_saved"));
      })
    },
    resetUserSettings(value) {
      for (let object in this.userSettings.settingsValue) {
        this.userSettings.settingsValue[object].value = value;
      }
    },
    resetTableSettings(value) {
      for (let object in this.tmpTableSettings.settingsValue) {
        this.tmpTableSettings.settingsValue[object].value = value;
      }
    },
    isAxeInterventionBretelle(axeInterventionId) {
      return !this.isEmpty(axeInterventionId) && this.axeInterventionList.find(axe => axe.id === axeInterventionId).technicalName === "BRETELLE";
    },
    getFilterValues() {
      this.searchContent = this.userParams.intervention.searchValue;
      this.author = this.userParams.intervention.author;
      this.callOrigin = this.userParams.intervention.callOrigin;
      this.axeInterventionId = this.userParams.intervention.axeInterventionId;
      this.sens = this.userParams.intervention.sens;
      this.typeIntervention = this.userParams.intervention.typeIntervention;
      this.ddp = this.userParams.intervention.ddp;
      this.presenceRi = this.userParams.intervention.presenceRi;
      this.filterAgerId = this.userParams.intervention.filterAgerId;
      this.filterCeiId = this.userParams.intervention.filterCeiId;
      this.startDate = this.userParams.intervention.startDate ? new Date(this.userParams.intervention.startDate) : null;
      this.endDate = this.userParams.intervention.endDate ? new Date(this.userParams.intervention.endDate) : null;
      this.noInterCei = this.userParams.intervention.noInterCei;
    },
    resetFilter() {
      this.$store.dispatch("resetInterventionUserParams");
      this.userParams = this.$store.state.userParams;
      this.getFilterValues();
      this.reloadInterventions();
    },
    clearDateRange() {
      this.startDate = null;
      this.endDate = null;
      this.userParams.intervention.startDate = null;
      this.userParams.intervention.endDate = null;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions();
    },
    toggleOrigineAppel() {
      if (this.origineAppel) {
        this.origineAppel = false;
        this.userSettings.settingsValue["callTime"].value = false;
        this.userSettings.settingsValue["callDate"].value = false;
        this.userSettings.settingsValue["callOrigin"].value = false;
        this.userSettings.settingsValue["observation"].value = false;
      } else {
        this.origineAppel = true;
        this.userSettings.settingsValue["callTime"].value = true;
        this.userSettings.settingsValue["callDate"].value = true;
        this.userSettings.settingsValue["callOrigin"].value = true;
        this.userSettings.settingsValue["observation"].value = true;
      }
    },
    toggleLieuIntervention() {
      if (this.lieuIntervention) {
        this.lieuIntervention = false;
        this.userSettings.settingsValue["cei"].value = false;
        this.userSettings.settingsValue["agents"].value = false;
        this.userSettings.settingsValue["axe"].value = false;
        this.userSettings.settingsValue["sens"].value = false;
        this.userSettings.settingsValue["voie"].value = false;
        this.userSettings.settingsValue["commune"].value = false;
        this.userSettings.settingsValue["heureArriveeSite"].value = false;
        this.userSettings.settingsValue["heureDepartSite"].value = false;
        this.userSettings.settingsValue["localisation"].value = false;
        this.userSettings.settingsValue["noBretelle"].value = false;
        this.userSettings.settingsValue["noAutoroute"].value = false;

      } else {
        this.lieuIntervention = true;
        this.userSettings.settingsValue["cei"].value = true;
        this.userSettings.settingsValue["agents"].value = true;
        this.userSettings.settingsValue["axe"].value = true;
        this.userSettings.settingsValue["sens"].value = true;
        this.userSettings.settingsValue["voie"].value = true;
        this.userSettings.settingsValue["commune"].value = true;
        this.userSettings.settingsValue["heureArriveeSite"].value = true;
        this.userSettings.settingsValue["heureDepartSite"].value = true;
        this.userSettings.settingsValue["localisation"].value = true;
        this.userSettings.settingsValue["noBretelle"].value = true;
        this.userSettings.settingsValue["noAutoroute"].value = true;

      }
    },
    toggleIntervention() {
      if (this.intervention) {
        this.intervention = false;
        this.userSettings.settingsValue["heureDebut"].value = false;
        this.userSettings.settingsValue["heureFin"].value = false;
        this.userSettings.settingsValue["circonstances"].value = false;

      } else {
        this.intervention = true;
        this.userSettings.settingsValue["heureDebut"].value = true;
        this.userSettings.settingsValue["heureFin"].value = true;
        this.userSettings.settingsValue["circonstances"].value = true;
      }
    },
    toggleEnvironnement() {
      if (this.environnement) {
        this.environnement = false;
        this.userSettings.settingsValue["typeIntervention"].value = false;
        this.userSettings.settingsValue["precisionNiveau1"].value = false;
        this.userSettings.settingsValue["precisionNiveau2"].value = false;
        this.userSettings.settingsValue["etatChaussee"].value = false;
        this.userSettings.settingsValue["conditionsMeteo"].value = false;
        this.userSettings.settingsValue["intervenants"].value = false;
        this.userSettings.settingsValue["actionsRealisees"].value = false;
        this.userSettings.settingsValue["presenceTiersResponsable"].value = false;

      } else {
        this.environnement = true;
        this.userSettings.settingsValue["typeIntervention"].value = true;
        this.userSettings.settingsValue["precisionNiveau1"].value = true;
        this.userSettings.settingsValue["precisionNiveau2"].value = true;
        this.userSettings.settingsValue["etatChaussee"].value = true;
        this.userSettings.settingsValue["conditionsMeteo"].value = true;
        this.userSettings.settingsValue["intervenants"].value = true;
        this.userSettings.settingsValue["actionsRealisees"].value = true;
        this.userSettings.settingsValue["presenceTiersResponsable"].value = true;
      }
    },
    displayColumnFor(id) {
      if(!this.tableSettings) return true;
      if(!this.tableSettings.settingsValue[id]) return true;
      return this.tableSettings.settingsValue[id].value;
    },
  },
  computed: {
    canDelete() {
      return this.canDeleteIntervention()
    },
    canCreate() {
      return this.canCreateIntervention()
    },
    canDeleteSelectedInterventions() {
      return this.canDeleteInterventions(this.selectedRows);
    },
  },
  watch: {
    searchContent: function (newVal) {
      if (this.intervLoading) return;
      this.userParams.intervention.searchValue = newVal;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    author() {
      if (this.intervLoading) return;
      this.userParams.intervention.author = this.author;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    callOrigin() {
      if (this.intervLoading) return;
      this.userParams.intervention.callOrigin = this.callOrigin;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    axeInterventionId() {
      if (this.intervLoading) return;
      this.userParams.intervention.axeInterventionId = this.axeInterventionId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    sens() {
      if (this.intervLoading) return;
      this.userParams.intervention.sens = this.sens;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    typeIntervention() {
      if (this.intervLoading) return;
      this.userParams.intervention.typeIntervention = this.typeIntervention;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    ddp() {
      if (this.intervLoading) return;
      this.userParams.intervention.ddp = this.ddp;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    presenceRi() {
      if (this.intervLoading) return;
      this.userParams.intervention.presenceRi = this.presenceRi;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    filterAgerId() {
      if (this.intervLoading) return;
      this.userParams.intervention.filterAgerId = this.filterAgerId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    filterCeiId() {
      if (this.intervLoading) return;
      this.userParams.intervention.filterCeiId = this.filterCeiId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    startDate() {
      if (this.intervLoading) return;
      this.userParams.intervention.startDate = this.startDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    endDate() {
      if (this.intervLoading) return;
      this.userParams.intervention.endDate = this.endDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    noInterCei() {
      if (this.intervLoading) return;
      this.userParams.intervention.noInterCei = this.noInterCei;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadInterventions()
    },
    "userSettings.settingsValue": {
      handler() {
        this.origineAppel = this.userSettings.settingsValue["callTime"] && this.userSettings.settingsValue["callDate"].value && this.userSettings.settingsValue["callOrigin"].value && this.userSettings.settingsValue["observation"].value;
        this.lieuIntervention = this.userSettings.settingsValue["cei"].value && this.userSettings.settingsValue["agents"].value && this.userSettings.settingsValue["axe"].value && this.userSettings.settingsValue["sens"].value && this.userSettings.settingsValue["voie"].value && this.userSettings.settingsValue["commune"].value && this.userSettings.settingsValue["heureArriveeSite"].value && this.userSettings.settingsValue["heureDepartSite"].value && this.userSettings.settingsValue["localisation"].value && this.userSettings.settingsValue["noBretelle"].value && this.userSettings.settingsValue["noAutoroute"].value;
        this.intervention = this.userSettings.settingsValue["heureDebut"].value && this.userSettings.settingsValue["heureFin"].value && this.userSettings.settingsValue["circonstances"].value;
        this.environnement = this.userSettings.settingsValue["typeIntervention"].value && this.userSettings.settingsValue["precisionNiveau1"].value && this.userSettings.settingsValue["precisionNiveau2"].value && this.userSettings.settingsValue["etatChaussee"].value && this.userSettings.settingsValue["conditionsMeteo"].value && this.userSettings.settingsValue["intervenants"].value && this.userSettings.settingsValue["actionsRealisees"].value && this.userSettings.settingsValue["presenceTiersResponsable"].value;
      },
      deep: true
    },
  }
}
</script>

<template>
  <template v-if="loading">
    <ProgressSpinner style="position: absolute; top: 50%; left: 50%;"/>
  </template>
  <template v-else>
    <AkFormList :display-go-back="false" :ref="ref" :title="$t('intervention.title')">
      <template v-slot:action>
        <div class="action-bar">
          <span class="p-input-icon-left search-bar-desktop">
            <i class="pi pi-search"/>
            <InputText v-model="searchContent" @keyup="reloadInterventions()" :placeholder="$t('search_here')"/>
          </span>
          <button @click="openTableSettingsDialog()" class="btn btn-inverse-dark">
            <i class="fe fe-settings pr-1"/>
            {{ $t('intervention_list.settings_intervention_table') }}
          </button>
          <button @click="openSettingsDialog()" class="btn btn-inverse-dark">
            <i class="fe fe-settings pr-1"/>
            {{ $t('intervention_list.settings_custom_excel') }}
          </button>
          <button @click="downloadCustom()" class="btn btn-inverse-primary"
                  :disabled="isCustomExportDownloading">
            <i class="fe fe-download pr-1" v-if="!isCustomExportDownloading"/>
            <i class="pi pi-spin pi-spinner mr-1" v-if="isCustomExportDownloading"></i>
            {{ $t('intervention_list.download_custom_excel') }}
          </button>
          <button @click="download()" class="btn btn-inverse-primary"
                  :disabled="isExportDownloading">
            <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
            <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
            {{ $t('intervention_list.download_excel') }}
          </button>
          <button @click="downloadFull()" class="btn btn-inverse-primary"
                  :disabled="isFullExportDownloading">
            <i class="fe fe-download pr-1" v-if="!isFullExportDownloading"/>
            <i class="pi pi-spin pi-spinner mr-1" v-if="isFullExportDownloading"></i>
            {{ $t('intervention_list.download_excel_full') }}
          </button>
        </div>
      </template>
      <template v-slot:list>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <div class="action-card">
                  <div>
                    <button @click="displayLightInterventionBlock()"
                            class="btn btn-inverse-primary" :disabled="isFullExportDownloading">
                      <i class="fe fe-plus pr-1"/>
                      {{ $t('intervention_list.add_light_intervention') }}
                    </button>
                    <RouterLink :to="{ name: 'interventionCreate' }" class="btn btn-inverse-primary">
                      <i class="fe fe fe-plus pr-1"/>{{ $t('intervention_list.add_full_intervention') }}
                    </RouterLink>
                  </div>
                  <Button
                      v-if="canDelete"
                      class="btn btn-inverse-danger"
                      @click="deleteSelectedRows()">
                    <i class="fe fe-trash pr-1"/>
                    {{ $t('intervention.delete_selected_interventions') }}
                  </Button>
                </div>

                <div class="table-responsive mt-1" v-if="displayCreateLightIntervention">
                  <DataTable
                      :always-show-paginator=false :paginator=false :rows=1
                      v-if="true"
                      :loading="loading"
                      :value="this.newIntervention"
                      class="table"
                      :sortOrder=-1
                      removableSort
                      responsiveLayout="scroll"
                      stripedRows
                      dataKey="id">
                    <template #empty>
                      {{ $t("list_empty") }}
                    </template>
                    <Column :header="$t('cei_label')">
                      <template #body="slotProps">
                        <AkDropdown
                            :filter="true"
                            v-if="userCeis.length>1"
                            label=""
                            v-model="slotProps.data.ceiId"
                            :options=userCeis
                            option-value="id"
                            option-label="label"
                            :class-name="(Object.keys(this.creationErrors).includes('ceiId') ? 'datatable-validation-error' : '')"/>
                        <AkInputText
                            v-else
                            disabled
                            :label="$t('cei_label')"
                            v-text="slotProps.data.ceiLabel"
                            class-name=""/>
                      </template>
                    </Column>
                    <Column field="call_date_hour" :header="$t('intervention.call_date_hour')">
                      <template #body="slotProps">
                        <AkDateTime
                            v-model="slotProps.data.callDate"
                            :submitted="submitted"
                            :showTime="false"
                            :max-date="new Date()"
                            placeholder="dd/mm/yyyy"
                            :class-name="(Object.keys(this.creationErrors).includes('callDate') ? 'datatable-validation-error' : '')"/>
                        <AkInputMask
                            v-model="slotProps.data.callTime"
                            :class-name="(Object.keys(this.creationErrors).includes('callTime') ? 'datatable-validation-error' : '')"
                            mask="99:99"
                            :onchange="this.checkInterventionTimeValidity(slotProps.data)"
                            placeholder="__:__"/>
                      </template>
                    </Column>
                    <Column field="callOrigin" :header="$t('intervention.call_origin')">
                      <template #body="slotProps">
                        <AkDropdown
                            :filter="true"
                            label=""
                            v-model="slotProps.data.callOrigin"
                            :options=origineAppelConst
                            option-value="value"
                            option-label="label"
                            :class-name="(Object.keys(this.creationErrors).includes('callOrigin') ? 'datatable-validation-error' : '')"/>
                        <AkInputText v-if="'AUTRE' === slotProps.data.callOrigin"
                                     label=""
                                     v-model="slotProps.data.otherCallOrigin"
                                     :placeholder="$t('intervention.other_call_origin')"
                                     :class-name="''"
                        />
                      </template>
                    </Column>
                    <Column field="axe_sens" :header="$t('intervention.axe_sens')">
                      <template #body="slotProps">
                        <AkDropdown
                            :filter="true"
                            label=""
                            v-model="slotProps.data.axeInterventionId"
                            :options="axeInterventionList"
                            option-value="id"
                            option-label="libelle"
                            :class-name="(Object.keys(this.creationErrors).includes('axeInterventionId') ? 'datatable-validation-error' : '')"/>
                        <template v-if="isAxeInterventionBretelle(slotProps.data.axeInterventionId)">
                          <AkInputText
                              label=""
                              v-model="slotProps.data.noBretelle"
                              placeholder="N° Bretelle"
                              :regexp="prValidatorRegexpFormat"
                              :class-name="(Object.keys(this.creationErrors).includes('noBretelle') ? 'datatable-validation-error' : '')"/>
                          <AkDropdown
                              label=""
                              :options="axeInterventionAutorouteList"
                              option-value="id"
                              option-label="libelle"
                              v-model="slotProps.data.autorouteId"
                              placeholder="N° Autoroute"
                              :class-name="(Object.keys(this.creationErrors).includes('noBretelle') ? 'datatable-validation-error' : '')"/>
                        </template>
                        <AkDropdownMultiple
                            label=""
                            v-model="slotProps.data.sensListTmp"
                            :options=sensConst
                            option-value="value"
                            option-label="label"
                            :filter="true"
                            :class-name="(Object.keys(this.creationErrors).includes('sens') ? 'datatable-validation-error' : '')"/>
                      </template>
                    </Column>
                    <Column field="localisation">
                      <template #header>
                        {{ $t('intervention.pr') }} <i class="fa fa-info-circle tooltip-centered"
                                                       v-tooltip="$t('intervention.pr_format_tooltip')"/>
                        / {{ $t('intervention.voie') }}
                      </template>
                      <template #body="slotProps">
                        <AkInputText
                            label=""
                            v-model="slotProps.data.localisation"
                            :regexp="prValidatorRegexpFormat"
                            :class-name="(Object.keys(this.creationErrors).includes('localisation') ? 'datatable-validation-error' : '')"/>
                        <AkInputText
                            label=""
                            v-model="slotProps.data.voie"
                            :class-name="(Object.keys(this.creationErrors).includes('voie') ? 'datatable-validation-error' : '')"/>
                      </template>
                    </Column>
                    <Column field="heure_arrivee_depart" :header="$t('intervention.heure_arrivee_depart')">
                      <template #body="slotProps">
                        <AkInputMask
                            v-model="slotProps.data.heureArriveeSite"
                            :class-name="(Object.keys(this.creationErrors).includes('heureArriveeSite') ? 'datatable-validation-error' : '')"
                            mask="99:99"
                            :onchange="this.checkInterventionTimeValidity(slotProps.data)"
                            placeholder="__:__"/>
                        <AkInputMask
                            v-model="slotProps.data.heureDepartSite"
                            :class-name="(Object.keys(this.creationErrors).includes('heureDepartSite') ? 'datatable-validation-error' : '')"
                            mask="99:99"
                            :onchange="this.checkInterventionTimeValidity(slotProps.data)"
                            placeholder="__:__"/>
                      </template>
                    </Column>
                    <Column field="type_intervention" :header="$t('intervention.type_intervention')">
                      <template #body="slotProps">
                        <AkDropdown
                            :filter="true"
                            label=""
                            v-model="slotProps.data.typeIntervention"
                            :options=typeInterventionConst
                            option-value="value"
                            option-label="label"
                            :class-name="(Object.keys(this.creationErrors).includes('typeIntervention') ? 'datatable-validation-error' : '')"
                            :(onBlur)="updateTypeIntervention(slotProps.data)"/>
                        <AkDropdown
                            :filter="true"
                            label=""
                            v-if="precision1"
                            v-model="slotProps.data.precisionNiveau1"
                            :options="this.getPrecision1Const(slotProps.data.typeIntervention)"
                            option-value="value"
                            option-label="label"
                            class-name=""/>
                        <AkInputText v-if="'AUTRE' === slotProps.data.typeIntervention"
                                     label=""
                                     v-model="slotProps.data.otherTypeIntervention"
                                     :class-name="''"
                        />
                      </template>
                    </Column>
                    <Column field="ddp" :header="$t('ddp.title_short')">
                      <template #body="slotProps">
                        <AkCheckbox
                            v-model="slotProps.data.ddp"
                            style="margin-bottom: 0 !important;"
                            class-name="" label=""/>
                      </template>
                    </Column>
                    <Column field="ri" :header="$t('intervention.ri')">
                      <template #body="slotProps">
                        <AkCheckbox
                            v-model="slotProps.data.presenceRi"
                            style="margin-bottom: 0 !important;"
                            class-name="" label=""/>
                      </template>
                    </Column>
                    <template #footer>
                      <div class="d-flex align-items-center justify-content-between">
                        <AkInputTextArea
                            style="width: 90% !important;"
                            :label="$t('intervention.observation')"
                            :rows=3
                            v-model="this.newIntervention[0].observation"
                            class-name=""/>

                        <button
                            style="height: 20px;"
                            class="btn btn-xs btn-inverse-primary"
                            @click="createIntervention(this.newIntervention[0])">
                          <i class="fe fe-save"/>
                        </button>
                      </div>
                    </template>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AkFormList>
    <AkFormList :display-go-back="false">
      <template v-slot:list>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <div class="action-card">
                  <Button
                      v-text="this.buttonChangeInterventionLabel"
                      class="btn btn-inverse-primary"
                      @click="changeAffichageIntervention()"/>
                  <button @click="resetFilter()" class="btn btn-inverse-primary">{{
                      $t('reset_filter')
                    }}
                  </button>
                </div>
                <div class="mt-3">
                  <div class="filters">
                    <div class="date">
                      <AkCalendar :label="$t('date_range') + ' du'" :max-date="endDate" v-model="startDate"
                                  class-name="" selection-mode="single" style="padding-left: 0 !important;"/>
                      <AkCalendar :label="'Au'" v-model="endDate" :min-date="startDate" selection-mode="single"
                                  class-name="" style="padding-left: 0 !important;"/>
                      <i v-if="this.startDate || this.endDate" class="pi pi-times"
                         style="padding-top: 15px; cursor: pointer; color: #6c757d;" @click="clearDateRange()"/>
                    </div>
                    <AkYesNoButton :label="$t('intervention.presence_ri')"
                                   class-name="" v-model="presenceRi" :options="yesNoEmpty"/>
                  </div>

                  <div class="filters">
                    <AkInputText v-model="noInterCei"
                                 :label="$t('intervention.no_intervention_cei')"
                                 class-name=""/>
                    <AkInputText v-model="author"
                                 :label="$t('author')"
                                 class-name=""/>
                    <AkDropdown :options="userAgers"
                                v-if="!(this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI())"
                                v-model="filterAgerId"
                                :label="$t('intervention.ager')"
                                option-value="id"
                                option-label="label"
                                class-name=""
                                :show-clear=true />
                    <AkDropdown :options="userCeis"
                                v-if="!(this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI())"
                                v-model="filterCeiId"
                                :label="$t('intervention.cei')"
                                option-value="id"
                                option-label="label"
                                class-name=""
                                :show-clear=true />
                    <AkDropdown :options="origineAppelConst"
                                v-model="callOrigin"
                                :label="$t('intervention.call_origin')"
                                option-value="value"
                                option-label="label"
                                class-name=""
                                :show-clear=true />
                    <AkDropdown :options="axeInterventionList"
                                v-model="axeInterventionId"
                                :label="$t('intervention.axe')"
                                option-value="id"
                                class-name=""
                                option-label="libelle"
                                :show-clear=true />
                    <AkDropdown :options="sensConst"
                                v-model="sens"
                                :label="$t('intervention.sens')"
                                option-value="value"
                                option-label="label"
                                class-name=""
                                :show-clear=true />
                    <AkDropdown :options="typeInterventionConst"
                                v-model="typeIntervention"
                                class-name=""
                                :label="$t('intervention.type_intervention')"
                                option-value="value"
                                option-label="label"/>
                    <AkYesNoButton :label="$t('ddp.title_short')"
                                   class-name="" v-model="ddp" :options="yesNoEmpty"/>
                  </div>
                </div>
              </div>
              <div class="table-responsive" id="interventionListDatatable">


                <DataTable :always-show-paginator=false :rows=10
                           lazy paginator
                           :totalRecords="totalRecords"
                           @page="paginate($event)"
                           @sort="paginate($event)"
                           v-if="true"
                           :loading="intervLoading"
                           :rowsPerPageOptions="[10,20,50]"
                           :value="interventions"
                           class="table stickyTable"
                           sortField="call_date"
                           :sortOrder=-1
                           scrollable
                           scrollHeight="600px"
                           responsive-layout="scroll"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort stateStorage="session" stateKey="dt-intervention-list"
                           stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column bodyStyle="vertical-align: middle;"
                          headerStyle="width: 200px;">
                    <template #body="slotProps">
                      <button v-if="canUpdateIntervention(slotProps.data) && this.userIntervention"
                              class="btn btn-xs btn-inverse-primary"
                              @click="updateIntervention(slotProps.data)">
                        <i class="fe fe-save"/>
                      </button>
                      <RouterLink
                          :to="{name: 'interventionDetails', params: {id: slotProps.data.id}}"
                          class="btn btn-xs btn-inverse-primary ml-1">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <button v-if="canDelete || this.userIntervention" v-tooltip="'Supprimer l\'intervention'"
                              class="btn btn-xs btn-inverse-danger ml-1"
                              @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/>
                      </button>

                    </template>
                  </Column>
                  <template v-if="canDelete">
                    <Column headerStyle="width: 3rem">
                      <template #header>
                        <AkCheckbox v-model="selectAll" @change="onSelectAllChange"/>
                      </template>
                      <template #body="slotProps">
                        <AkCheckbox v-model="slotProps.data.selected" @change="onSelectedChange(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('noInterventionCei')">
                    <Column sortField="no_intervention_cei" field="noInterventionCei"
                            :header="$t('intervention.no_intervention_cei')" :sortable="true">
                      <template #body="slotProps">
                        {{ slotProps.data.noInterventionCei }}
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('author')">
                    <Column field="fullName" sort-field="j_full_name" :header="$t('author')" :sortable="true">
                      <template #body="slotProps">
                        {{ slotProps.data.fullName }}
                      </template>
                    </Column>
                  </template>
                  <template
                      v-if="!(this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI()) && this.displayColumnFor('ager')">
                    <Column field="ager_label" :header="$t('intervention.ager')"
                            :sortable="false">
                      <template #body="slotProps">
                        {{ slotProps.data.agerLabel }}
                      </template>
                    </Column>
                  </template>
                  <template
                      v-if="!(this.isUserGroupDirectionCEI() || this.isUserGroupRiCEI()) && this.displayColumnFor('cei')">
                    <Column sortField="j_cei_label" field="cei_label" :header="$t('intervention.cei')"
                            :sortable="true">
                      <template #body="slotProps">
                        {{ slotProps.data.ceiLabel }}
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('callDate')">
                    <Column sortField="call_date" field="callDate" :header="$t('intervention.call_date_hour')"
                            :sortable="true">
                      <template #body="slotProps">
                        <AkDateTime v-model="slotProps.data.callDate"
                                    :showTime="false"
                                    placeholder="dd/mm/yyyy"
                                    :max-date="new Date()"
                                    :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                    :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('callDate') ? 'datatable-validation-error' : '')"/>
                        <AkInputMask v-model="slotProps.data.callTime"
                                     :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('callTime') ? 'datatable-validation-error' : '')"
                                     mask="99:99"
                                     placeholder="__:__"
                                     :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                     :onchange="this.checkInterventionTimeValidity(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('callOrigin')">
                    <Column sortField="call_origin" field="callOrigin" :header="$t('intervention.call_origin')">
                      <template #body="slotProps">
                        <AkDropdown label=""
                                    :filter="true"
                                    v-model="slotProps.data.callOrigin"
                                    :options=origineAppelConst
                                    option-value="value"
                                    option-label="label"
                                    :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                    :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('callOrigin') ? 'datatable-validation-error' : '')"/>
                        <AkInputText
                            v-if="'AUTRE' === slotProps.data.callOrigin && null != slotProps.data.otherCallOrigin"
                            label=""
                            v-model="slotProps.data.otherCallOrigin"
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            :class-name="''"
                        />
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('axeSensIntervention')">
                    <Column field="axe_sens" :header="$t('intervention.axe_sens')">
                      <template #body="slotProps">
                        <AkDropdown label=""
                                    :filter="true"
                                    v-model="slotProps.data.axeInterventionId"
                                    :options="axeInterventionList"
                                    option-value="id"
                                    option-label="libelle"
                                    :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                    :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('axeInterventionId') ? 'datatable-validation-error' : '')"/>
                        <template v-if="isAxeInterventionBretelle(slotProps.data.axeInterventionId)">
                          <AkInputText
                              label=""
                              v-model="slotProps.data.noBretelle"
                              placeholder="N° Bretelle"
                              :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                              :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('noBretelle') ? 'datatable-validation-error' : '')"/>
                          <AkDropdown
                              label=""
                              :options="axeInterventionAutorouteList"
                              option-value="id"
                              option-label="libelle"
                              v-model="slotProps.data.autorouteId"
                              placeholder="N° Autoroute"
                              :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                              :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('autorouteId') ? 'datatable-validation-error' : '')"/>
                        </template>
                        <AkDropdownMultiple
                            label=""
                            v-model="slotProps.data.sensListTmp"
                            :options=sensConst
                            option-value="value"
                            option-label="label"
                            :filter="true"
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            :class-name="(Object.keys(this.creationErrors).includes('sens') ? 'datatable-validation-error' : '')"/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('localisation')">
                    <Column field="localisation" :header="$t('intervention.pr_voie')">
                      <template #body="slotProps">
                        <AkInputText
                            label=""
                            v-model="slotProps.data.localisation"
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('localisation') ? 'datatable-validation-error' : '')"/>
                        <AkInputText
                            label=""
                            v-model="slotProps.data.voie"
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('voie') ? 'datatable-validation-error' : '')"/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('heureArriveeDepart')">
                    <Column field="heure_arrivee_depart" :header="$t('intervention.heure_arrivee_depart')">
                      <template #body="slotProps">
                        <AkInputMask v-model="slotProps.data.heureArriveeSite"
                                     :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('heureArriveeSite') ? 'datatable-validation-error' : '')"
                                     mask="99:99"
                                     placeholder="__:__"
                                     :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                     :onchange="this.checkInterventionTimeValidity(slotProps.data)"/>
                        <AkInputMask v-model="slotProps.data.heureDepartSite"
                                     :class-name="(Object.keys(this.editionErrors[slotProps.data.id]).includes('heureDepartSite') ? 'datatable-validation-error' : '')"
                                     mask="99:99"
                                     placeholder="__:__"
                                     :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                     :onchange="this.checkInterventionTimeValidity(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('typeIntervention')">
                    <Column field="type_intervention" :header="$t('intervention.type_intervention')">
                      <template #body="slotProps">
                        <AkDropdown
                            :filter="true"
                            label=""
                            v-model="slotProps.data.typeIntervention"
                            :options=typeInterventionConst
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            option-value="value"
                            option-label="label"
                            :class-name="(Object.keys(this.creationErrors).includes('typeIntervention') ? 'datatable-validation-error' : '')"/>
                        <AkInputText
                            v-if="'AUTRE' === slotProps.data.typeIntervention && null !=slotProps.data.otherTypeIntervention"
                            label=""
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            v-model="slotProps.data.otherTypeIntervention"
                            :class-name="''"
                        />
                      </template>
                      <AkDropdownMultiple
                          label=""
                          v-model="slotProps.data.sensListTmp"
                          :options=sensConst
                          option-value="value"
                          option-label="label"
                          :filter="true"
                          :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                          :class-name="(Object.keys(this.creationErrors).includes('sens') ? 'datatable-validation-error' : '')"/>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('ddp')">
                    <Column field="ddp" :header="$t('ddp.title_short')">
                      <template #body="slotProps">
                        <AkCheckbox
                            v-model="slotProps.data.ddp"
                            style="margin-bottom: 0 !important;"
                            :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            class-name="" label=""/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('ri')">
                    <Column field="ri" :header="$t('intervention.ri')">
                      <template #body="slotProps">
                        <AkCheckbox v-model="slotProps.data.presenceRi"
                                    style="margin-bottom: 0 !important;"
                                    :disabled="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                                    class-name="" label=""/>
                      </template>
                    </Column>
                  </template>
                  <template v-if="this.displayColumnFor('observation')">
                    <Column field="observation" :header="$t('intervention.observation')">
                      <template #body="slotProps">
                        <AkInputTextArea
                            label=""
                            :rows=3
                            v-model="slotProps.data.observation"
                            @click="this.openObservationDialog(slotProps.data)"
                            :readonly="!canUpdateIntervention(slotProps.data) || !this.userIntervention"
                            class-name=""/>
                      </template>
                    </Column>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:extra>
        <AkDialog
            ref="dialogObservation"
            :auto-hide-on-validate="true"
            :validate-label="$t('close')"
            :title="$t('intervention.observation')"
            width="750px"
            :no-cancel-label="true">
          <AkInputTextArea
              label=""
              :rows=10
              :disabled="!canUpdateIntervention(this.current) || !this.userIntervention"
              v-model="this.current.observation"
              class-name=""/>
        </AkDialog>
        <AkDialog ref="dialogDelete"
                  :auto-hide-on-validate="true"
                  :cancel-label="$t('cancel')"
                  :title="$t('intervention.delete_dialog')"
                  :validate-label="$t('validate')"
                  width="450px"
                  @validate="deleteIntervention">
          <div class="confirmation-content">
            <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
            <span>{{ $t('intervention.confirm_delete') }}</span>
          </div>
        </AkDialog>
        <AkDialog ref="settingsDialog"
                  :auto-hide-on-validate="true"
                  :cancel-label="$t('cancel')"
                  :title="$t('intervention.settings_dialog')"
                  :validate-label="$t('validate')"
                  width="650px"
                  @validate="updateSettings()">
          <div class="confirmation-content d-flex flex-row justify-content-between" style="gap: 10px;">
            <div class="d-flex align-items-center">
              <i class="fe fe-info mr-1" style="font-size: 2rem"/>
              <div class="d-flex flex-column">
                <span>{{ $t('intervention.update_settings') }}</span>
                <span>Cocher les données que vous souhaitez voir</span>
              </div>
            </div>
            <div class="d-flex flex-column" style="gap: 10px;">
              <button :v-tooltip="$t('intervention_list.untick_all')"
                      class="btn btn-outline-primary btn-xs"
                      @click="resetUserSettings(false)">
                {{ $t('intervention_list.untick_all') }}
              </button>
              <button :v-tooltip="$t('intervention_list.tick_all')"
                      class="btn btn-outline-primary btn-xs"
                      @click="resetUserSettings(true)">
                {{ $t('intervention_list.tick_all') }}
              </button>
            </div>
          </div>
          <div class="d-flex flex-column checkbox">
            <ak-checkbox v-model="userSettings.settingsValue['noInterDirif'].value"
                         :label="$t('userSettings.interventions.noInterDirif')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <ak-checkbox v-model="userSettings.settingsValue['noInterCei'].value"
                         :label="$t('userSettings.interventions.noInterCei')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <ak-checkbox v-model="userSettings.settingsValue['auteur'].value"
                         :label="$t('userSettings.interventions.auteur')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <div class="d-flex flex-row">
              <ak-checkbox v-model="origineAppel"
                           @click="toggleOrigineAppel()"
                           label="Origine de l'appel"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
              <i :class="'fe ' + (origineAppelDropdown ? 'fe-chevron-up' : 'fe-chevron-down')"
                 style="font-size: 1.5rem; cursor: pointer;position: relative; top: -4px; left: 5px;"
                 @click="() => origineAppelDropdown = !origineAppelDropdown"/>
            </div>

            <div class="pl-5" v-if="origineAppelDropdown">
              <ak-checkbox v-model="userSettings.settingsValue['callOrigin'].value"
                           :label="$t('userSettings.interventions.callOrigin')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['callDate'].value"
                           :label="$t('userSettings.interventions.callDate')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['callTime'].value"
                           :label="$t('userSettings.interventions.callTime')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['observation'].value"
                           :label="$t('userSettings.interventions.observation')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
            </div>

            <div class="d-flex flex-row">
              <ak-checkbox v-model="lieuIntervention"
                           @click="toggleLieuIntervention()"
                           label="Lieu d'intervention"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
              <i :class="'fe ' + (lieuInterventionDropdown ? 'fe-chevron-up' : 'fe-chevron-down')"
                 @click="() => lieuInterventionDropdown = !lieuInterventionDropdown"
                 style="font-size: 1.5rem; cursor: pointer;position: relative; top: -4px; left: 5px;"/>
            </div>

            <div class="pl-5" v-if="lieuInterventionDropdown">
              <ak-checkbox v-model="userSettings.settingsValue['ager'].value"
                           :label="$t('userSettings.interventions.ager')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
                           
              <ak-checkbox v-model="userSettings.settingsValue['cei'].value"
                           :label="$t('userSettings.interventions.cei')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['agents'].value"
                           :label="$t('userSettings.interventions.agents')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['axe'].value"
                           :label="$t('userSettings.interventions.axe')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['sens'].value"
                           :label="$t('userSettings.interventions.sens')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['voie'].value"
                           :label="$t('userSettings.interventions.voie')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['commune'].value"
                           :label="$t('userSettings.interventions.commune')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['heureArriveeSite'].value"
                           :label="$t('userSettings.interventions.heureArriveeSite')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['heureDepartSite'].value"
                           :label="$t('userSettings.interventions.heureDepartSite')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['localisation'].value"
                           :label="$t('userSettings.interventions.localisation')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['noBretelle'].value"
                           :label="$t('userSettings.interventions.noBretelle')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['noAutoroute'].value"
                           :label="$t('userSettings.interventions.noAutoroute')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
            </div>

            <div class="d-flex flex-row">
              <ak-checkbox v-model="intervention"
                           @click="toggleIntervention()"
                           label="Intervention"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
              <i :class="'fe ' + (interventionDropdown ? 'fe-chevron-up' : 'fe-chevron-down')"
                 @click="() => interventionDropdown = !interventionDropdown"
                 style="font-size: 1.5rem; cursor: pointer;position: relative; top: -4px; left: 5px;"/>
            </div>
            <div class="pl-5" v-if="interventionDropdown">
              <ak-checkbox v-model="userSettings.settingsValue['heureDebut'].value"
                           :label="$t('userSettings.interventions.heureDebut')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['heureFin'].value"
                           :label="$t('userSettings.interventions.heureFin')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['circonstances'].value"
                           :label="$t('userSettings.interventions.circonstances')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
            </div>

            <ak-checkbox v-model="userSettings.settingsValue['vehiculesImpliques'].value"
                         :label="$t('userSettings.interventions.vehiculesImpliques')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <ak-checkbox v-model="userSettings.settingsValue['presenceRi'].value"
                         :label="$t('userSettings.interventions.presenceRi')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <ak-checkbox v-model="userSettings.settingsValue['moyensHumains'].value"
                         :label="$t('userSettings.interventions.moyensHumains')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <ak-checkbox v-model="userSettings.settingsValue['moyensMateriels'].value"
                         :label="$t('userSettings.interventions.moyensMateriels')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>

            <div class="d-flex flex-row">
              <ak-checkbox v-model="environnement"
                           @click="toggleEnvironnement()"
                           label="Environnement"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
              <i :class="'fe ' + (environnementDropdown ? 'fe-chevron-up' : 'fe-chevron-down')"
                 @click="() => environnementDropdown = !environnementDropdown"
                 style="font-size: 1.5rem; cursor: pointer;position: relative; top: -4px; left: 5px;"/>
            </div>
            <div class="pl-5" v-if="environnementDropdown">
              <ak-checkbox v-model="userSettings.settingsValue['typeIntervention'].value"
                           :label="$t('userSettings.interventions.typeIntervention')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['precisionNiveau1'].value"
                           :label="$t('userSettings.interventions.precisionNiveau1')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['precisionNiveau2'].value"
                           :label="$t('userSettings.interventions.precisionNiveau2')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['etatChaussee'].value"
                           :label="$t('userSettings.interventions.etatChaussee')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['conditionsMeteo'].value"
                           :label="$t('userSettings.interventions.conditionsMeteo')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['intervenants'].value"
                           :label="$t('userSettings.interventions.intervenants')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['actionsRealisees'].value"
                           :label="$t('userSettings.interventions.actionsRealisees')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>

              <ak-checkbox v-model="userSettings.settingsValue['presenceTiersResponsable'].value"
                           :label="$t('userSettings.interventions.presenceTiersResponsable')"
                           class-name=""
                           style="margin-bottom: 0 !important;"/>
            </div>

            <ak-checkbox v-model="userSettings.settingsValue['ddp'].value"
                         :label="$t('userSettings.interventions.ddp')"
                         class-name=""
                         style="margin-bottom: 0 !important;"/>
          </div>
        </AkDialog>
        <AkDialog ref="tableSettingsDialog"
                  :auto-hide-on-validate="true"
                  :cancel-label="$t('cancel')"
                  :title="$t('intervention.table_settings_dialog')"
                  :validate-label="$t('validate')"
                  width="650px"
                  @validate="updateTableSettings()">
          <div class="confirmation-content d-flex flex-row justify-content-between" style="gap: 10px;">
            <div class="d-flex align-items-center">
              <i class="fe fe-info mr-1" style="font-size: 2rem"/>
              <div class="d-flex flex-column">
                <span>{{ $t('intervention.update_table_settings') }}</span>
                <span>Cocher les données que vous souhaitez voir</span>
              </div>
            </div>
            <div class="d-flex flex-column" style="gap: 10px;">
              <button :v-tooltip="$t('intervention_list.untick_all')"
                      class="btn btn-outline-primary btn-xs"
                      @click="resetTableSettings(false)">
                {{ $t('intervention_list.untick_all') }}
              </button>
              <button :v-tooltip="$t('intervention_list.tick_all')"
                      class="btn btn-outline-primary btn-xs"
                      @click="resetTableSettings(true)">
                {{ $t('intervention_list.tick_all') }}
              </button>
            </div>
          </div>
          <ak-checkbox
              v-for="setting in Object.keys(tmpTableSettings.settingsValue)"
              :key="setting"
              v-model="tmpTableSettings.settingsValue[setting].value"
              :label="$t('tableSettings.interventions.' + setting)"
              class-name="col-12 checkbox mt-2"
              style="margin-bottom: 0 !important;"/>
        </AkDialog>
      </template>
    </AkFormList>
  </template>
</template>