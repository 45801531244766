<script>
import backMixin from "@mixins/backMixin";
import messageMixin from "@mixins/messageMixin";
import loaderMixin from "@mixins/loaderMixin";

export default {
  mixins: [backMixin, messageMixin, loaderMixin],
  props: {
    title: String,
    displayGoBack: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>

<template>
  <div>
    <Message v-for="msg of messages" :key="msg.content" :closable="msg.closable" :severity="msg.severity" @close="closeMessage(msg)">
      {{ msg.content }}
    </Message>
    <a v-if="displayGoBack" class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1"/>{{ $t('back') }}</a>
    <slot name="tabs"></slot>
    <div class="row mb-2">
      <div class="col-md-3 mb-2 col-6">
        <!-- begin page title -->
        <div class="d-block d-sm-flex flex-nowrap align-items-center">
          <div class="page-title mb-2 mb-sm-0">
            <h1>{{ title }}</h1>
          </div>
        </div>
        <!-- end page title -->
      </div>
      <div class="col-md-9 text-right mb-md-0">
        <slot name="action"></slot>
      </div>
    </div>
    <slot name="search"></slot>
    <slot name="list"></slot>
    <slot name="extra"></slot>
  </div>
</template>