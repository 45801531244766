import Api from "@/services/api";

export default {
    getSettings(userId, settingsKey) {
        return Api().get(`/usersettings/${userId}/${settingsKey}`).then(res => res.data);
    },
    updateSettings(settings) {
        return Api().put(`/usersettings`, settings).then(res => res.data);
    },
    getInterventionSettings(userId) {
        return this.getSettings(userId, 'intervention_settings');
    },
    resetSettings(userId, settingsKey) {
        return Api().put(`/usersettings/${userId}/${settingsKey}/reset`).then(res => res.data);
    },
    resetInterventionSettings(userId) {
        return this.resetSettings(userId, 'intervention_settings');
    },
    getTableInterventionSettings(userId) {
        return this.getSettings(userId, 'table_intervention_settings');
    },
    resetTableInterventionSettings(userId) {
        return this.resetSettings(userId, 'table_intervention_settings');
    }
}